import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import constants from "../constants";

export default (props) => {
  const loc = useLocation();
  const q = new URLSearchParams(loc.search);
  const courseId = q.get("courseId");
  return (
    <div className="max-w-md h-screen m-auto flex-center">
      <div className="bg-white relative border-t-4 border-red-400 rounded-lg shadow-2xl pt-8 pb-10 px-4 sm:px-8 text-center max-w-sm flex flex-col items-center">
        <Link
          to="/"
          className="absolute right-0 top-0 mt-2 mr-2 text-gray-500 rounded-full bg-gray-100 hover:bg-gray-200 flex-center h-6 w-6"
        >
          <svg
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </Link>
        <div className="rounded-full bg-red-200 flex-center h-12 w-12">
          <svg
            className="h-6 w-6 text-red-900"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <h1 className="my-3 font-semibold text-lg">Payment Failed</h1>
        <p className="text-sm text-gray-800 px-1 my-2">
          Oops!! <br />
          We were not able to charge you due to some issue. If this persists
          just send me an email at <strong>{constants.EMAIL}</strong> and I'll
          try to resolve it
        </p>
        {courseId && (
          <Link
            replace
            to={`/courses/${courseId}`}
            className={`w-full mt-4 py-3 px-8 font-medium text-white rounded bg-gray-800 focus:bg-gray-900 hover:bg-gray-700
focus:outline-none focus:shadow-outline text-sm leading-5`}
          >
            Back to Course
          </Link>
        )}
        <Link
          replace
          to="/"
          className={`w-full mt-4 py-3 px-8 font-medium border border-gray-300 text-gray-900 rounded
hover:border-gray-700 focus:outline-none focus:shadow-outline text-sm leading-5`}
        >
          Go Home
        </Link>
      </div>
    </div>
  );
};
